import { useTheme } from "styled-components";
import Logo from "assets/png/logoFooter.png";
import packageJson from "../../../package.json";

export function AppVersion() {
  const theme = useTheme();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "nowrap",
        textWrap: "nowrap",
        padding: "0.5rem",
        backgroundColor: theme.colors.primary,
      }}
    >
      <img
        src={Logo}
        alt="Legal Control"
        style={{ width: "8rem", height: "2.5rem", objectFit: "contain" }}
      />
      <span style={{ color: theme.colors.background_white }}>
        Versão: {packageJson.version} Copyright © Legal Control. Todos os
        direitos reservados.
      </span>
    </div>
  );
}
