/* eslint-disable no-console */
/* eslint-disable react/state-in-constructor */
import React, { useCallback, useEffect, useMemo } from "react";
//import { LoggerController } from 'controllers';
import { isProd } from "utils/helpers";

interface ErrorProps {
  children?: React.ReactNode;
}
const ErrorBoundary: React.FC<ErrorProps> = ({ children }) => {
  const ignored = useMemo(() => ["ResizeObserver"], []);
  const onError = useCallback(
    (error: ErrorEvent) => {
      //alert(error);
      if (ignored.some((i) => error.message.match(`${i}`))) {
        console.warn({ Ignored: error });
        return;
      }
      console.error(error);
      //LoggerController.error(error.error || error);
      if (isProd()) window.location.href = "/error/500";
    },  
    [ignored]
  );
  useEffect(() => {
    window.addEventListener("error", onError);
    return () => {
      window.removeEventListener("error", onError);
    };
  }, [onError]);
  return <>{children}</>;
};
export default ErrorBoundary;
