import styled from "styled-components";

export const Container = styled.div`
  .login-container{
    padding: 1.3rem;
    background: white;
    border-radius: 0.8rem;
    box-shadow: rgba(0,0,0,0.15) 0px 0px 18px 0px;
    border: 1px solid rgba(0,0,0,0.25);
    margin-left: auto;
    margin-right: 20%;

    @media (max-width: 1300px) {
        margin: auto;
      }
  }
`