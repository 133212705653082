import { LogContextProps } from "contexts-types";
import { Columns, FilterProps, Props } from "props";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useInfiniteQuery } from "react-query";
import { ILogViewModel } from "viewModels";
import { useAuth } from "./auth";
import { IOrderFieldInputModel } from "inputModels";
import { LogController } from "controllers";

const PAGE_SIZE = 15;
const LogContext = createContext<LogContextProps>({} as LogContextProps);

const Log: React.FC<Props> = ({ children }) => {
  const { user, logged } = useAuth();
  const [orderBy, setOrder] = useState<IOrderFieldInputModel<Columns>[]>([]);
  const [logs, setLogs] = useState<ILogViewModel[]>([]);
  const [filter, setFilter] = useState<FilterProps>();

  const {
    data: results,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery(
    ["logs", orderBy, filter],
    ({ pageParam = 1 }) =>
      LogController.listAll({
        orderBy,
        page: pageParam,
        take: 5,
        term: filter?.term,
        filter: filter?.term ? filter?.filter : undefined,
      }).then((res) => res.data),
    {
      enabled: !!user?.id && logged,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1;
        return lastPage.length < PAGE_SIZE ? undefined : nextPage;
      },
    }
  );

  const handleFetch = () => {
    refetch();
  };

  const handleFetchNextPage = () => {
    fetchNextPage();
  };

  useEffect(() => {
    setLogs(results?.pages?.flat() ?? []);
  }, [results, setLogs]);

  return (
    <LogContext.Provider
      value={{
        logs,
        setLogs,
        orderBy,
        setOrder,
        filter,
        setFilter,
        handleFetch,
        handleFetchNextPage,
        hasNextPage,
        isFetchingNextPage,
      }}
    >
      {children}
    </LogContext.Provider>
  );
};

export const useLog: () => LogContextProps = () => useContext(LogContext);

export default Log;
