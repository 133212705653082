import { KeyboardArrowDown, KeyboardDoubleArrowDown, Search, Sort } from "@mui/icons-material";
import {
  Button,
  Chip,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { AppBar } from "components";
import { useLog } from "contexts/log";
import moment from "moment";
import { Columns } from "props";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { LogStatus } from "types/enums";

const Logs: React.FC = () => {
  const theme = useTheme();
  const { logs, orderBy, setOrder, hasNextPage, handleFetchNextPage, isFetchingNextPage } = useLog();
  const [isLoading] = useState(false);

  const {
    control,
    watch,
  } = useForm({
    shouldFocusError: false,
    reValidateMode: "onChange",
  });
  const data = watch();

  const handleSort = (key: Columns) => () => {
    setOrder((prev) => {
      const newOrder = [...prev];
      const oldOrderIdx = prev.findIndex((o) => o.key === key);
      if (oldOrderIdx === -1) {
        newOrder.push({
          key,
          direction: "asc",
        });
      } else if (newOrder[oldOrderIdx].direction === "asc") {
        newOrder[oldOrderIdx].direction = "desc";
      } else {
        newOrder.splice(oldOrderIdx, 1);
      }
      return newOrder;
    });
  };

  const getSortIcon = (key: Columns) => (
    <IconButton size="small" onClick={handleSort(key)}>
      {orderBy?.find((o) => o.key === key) ? (
        <KeyboardArrowDown
          sx={{
            transition: "all 0.5s ease-in",
            transform:
              orderBy.find((o) => o.key === key)?.direction === "asc"
                ? "rotate(-180deg)"
                : "",
          }}
        />
      ) : (
        <Sort />
      )}
    </IconButton>
  );

  return (
    <>
      <AppBar />
      <Container
        maxWidth="lg"
        sx={{ flexGrow: 1, height: "100vh", overflowY: "auto", pt: 10, pb: 4 }}
      >
        <TableContainer
          component={Paper}
          sx={{
            px: 5,
            pb: 2,
            height: "100%",
            flexGrow: 1,
            overflowY: "auto",
            overflowX: "auto",
          }}
        >
          <Grid display="flex" justifyContent="end" margin="0.5rem 0">
            <Controller
              control={control}
              name="filter"
              render={({ field: { onChange, value } }) => (
                <TextField
                  size="small"
                  label="Pesquisar"
                  variant="standard"
                  type="text"
                  placeholder="O que deseja buscar?"
                  sx={{ width: 250 }}
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    sx: {
                      pl: 1,
                    },
                    endAdornment: <Search />,
                  }}
                />
              )}
            />
          </Grid>
          <Table stickyHeader aria-label="list-logs">
            <TableHead>
              <TableRow
                sx={{
                  th: {
                    backgroundColor: "white",
                    "& p": {
                      fontWeight: "bold",
                      color: theme.palette.primary.main,
                    },
                    "& div": {
                      display: "flex",
                      alignItems: "center",

                      "& button": {
                        ml: 1,
                      },
                    },
                  },
                }}
              >
                <TableCell>
                  <Typography>#</Typography>
                </TableCell>
                <TableCell>
                  <Grid>
                    <Typography>Data</Typography>
                    {getSortIcon("date")}
                  </Grid>
                </TableCell>
                <TableCell>
                  <Typography>Status</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Metadata</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    <Grid
                      item
                      alignItems="center"
                      sx={{ minHeight: "65px !important" }}
                    >
                      <CircularProgress size={15} />
                      <Typography
                        paddingLeft={1}
                        variant="caption"
                        color="text"
                        sx={{ fontStyle: "italic" }}
                      >
                        Carregando registro de logs...
                      </Typography>
                    </Grid>
                  </TableCell>
                </TableRow>
              ) : !logs.length ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <Typography
                      paddingLeft={1}
                      variant="caption"
                      color="text"
                      sx={{ fontStyle: "italic" }}
                    >
                      Nenhum log encontrado...
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                logs.filter(l => data.filter ?
                  new Date(l.createdAt).toLocaleDateString("pt-BR", { day: "2-digit", month: "2-digit", year: "numeric" }).includes(data.filter) : logs).map(({ id, status, ...log }, idx) => (
                    <TableRow data-testid='rowLog'
                      key={id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell>
                        <Typography color="primary">
                          {(idx + 1).toString().padStart(2, "000")}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography sx={{ whiteSpace: "nowrap" }}>
                          {moment(log.createdAt).format("DD/MM/yyyy HH:mm")}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Chip
                          label={
                            status === LogStatus.PENDING
                              ? "Pendente"
                              : status === LogStatus.RESOLVED
                                ? "Resolvido"
                                : "Não resolvido"
                          }
                          variant="outlined"
                          color={
                            status === LogStatus.PENDING
                              ? "warning"
                              : status === LogStatus.RESOLVED
                                ? "info"
                                : "error"
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <Typography>{JSON.stringify(log.metadata)}</Typography>
                      </TableCell>
                    </TableRow>
                  ))
              )}
              {hasNextPage && (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    {isFetchingNextPage ? (
                      <Grid
                        item
                        alignItems="center"
                        sx={{ minHeight: "65px !important" }}
                      >
                        <CircularProgress size={15} />
                        <Typography
                          paddingLeft={1}
                          variant="caption"
                          color="text"
                          sx={{ fontStyle: "italic" }}
                        >
                          Carregando itens...
                        </Typography>
                      </Grid>
                    ) : (
                      <Button onClick={() => handleFetchNextPage()} sx={{ px: 2 }}>
                        <KeyboardDoubleArrowDown />
                        <Typography paddingLeft={2} variant="button">
                          Carregar mais...
                        </Typography>
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
};

export default Logs;
