import styled from "styled-components";

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const ModalContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 20px;
  overflow: auto;
`;

export const TransactionDetails = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  margin-top: 20px;

  .details-title {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.text_grey};
    text-align: end;
    padding: 10px;
  }
  .list-item {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    padding: 5px;

    .key {
      font-size: 13px;
      font-weight: 600;
    }
    .value {
      align-items: center;
      justify-content: center;
      align-content: center;
      font-size: 12px;
      color: ${({ theme }) => theme.colors.text_grey};
      text-align: end;

      .icon {
        align-content: center;
        align-items: center;
        justify-content: center;
        margin-right: 5px;
        margin-top: 4px;
        width: 12px;
        height: 12px;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;

export const HeaderAmount = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 10px;
  border-bottom: 1px solid #b4b8b8;
  width: 100%;

  .amount-title {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.text_grey};
    text-align: end;
  }
  .amount {
    display: flex;
    flex-direction: row;

    .prefix {
      font-size: 14px;
      font-weight: 600;
      position: relative;
      top: 8px;
      margin-right: 5px;
    }

    .value {
      font-size: 35px;
      font-weight: 600;
    }
  }
`;

export const Title = styled.p`
  padding: 10px;
  font-size: large;
  color: ${({ theme }) => theme.colors.text_grey};
  text-align: center;
`;
export const PaymentResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: center;
`;

export const StatusMessage = styled.p`
  font-size: large;
  color: ${({ theme }) => theme.colors.text_grey};
`;

export const PaymentMessage = styled.p`
  font-size: medium;
  color: ${({ theme }) => theme.colors.text_grey};
  text-align: center;
  padding: 10px;
`;

export const PaymentId = styled.p`
  font-size: small;
  color: ${({ theme }) => theme.colors.text_grey};
`;
