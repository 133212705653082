import axios from "axios";
import { IOrderedWithPaginationInputModel } from "inputModels";
import api from "services/api";
import { ILogUserViewModel, IResponse, IVoidResponse } from "viewModels";

const listAll = <T>(input?: IOrderedWithPaginationInputModel<T>) =>
  new Promise<IResponse<ILogUserViewModel[]>>((resolve, reject) => {
    api
      .get(
        `loguser/get-all?page=${input?.page}&take=${input?.take}${
          input?.term ? `&term=${input?.term}` : ""
        }${input?.filter ? `&filter=${input?.filter}` : ""}&${input?.orderBy
          ?.map(
            (o, i) =>
              `orderBy[${i}].key=${o.key}&orderBy[${i}].direction=${o.direction}`
          )
          .join("&")}`
      )
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const getReportFinancial = <T>(input?: IOrderedWithPaginationInputModel<T>) =>
  new Promise<IResponse<ILogUserViewModel[]>>((resolve, reject) => {
    api
      .get(
        `loguser/get-report-financial?page=${input?.page}&take=${input?.take}${input?.term ? `&term=${input?.term}` : ""
        }${input?.filter ? `&filter=${input?.filter}` : ""}&${input?.orderBy
          ?.map(
            (o, i) =>
              `orderBy[${i}].key=${o.key}&orderBy[${i}].direction=${o.direction}`
          )
          .join("&")}`
      )
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

interface IDocumentViewModel {
  cnpj: string;
}

const apiDocument = axios.create({
  baseURL: 'https://receitaws.com.br/v1/cnpj/cnpj'
});

const requestInfoCNPJ = (input?: IDocumentViewModel) =>
  new Promise<IVoidResponse>((resolve, reject) => {
    apiDocument
      .get(`https://receitaws.com.br/v1/cnpj/${input}`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const LogUserController = {
  listAll,
  requestInfoCNPJ,
  getReportFinancial,
};
