import {
  Edit,
  DoDisturb,
  Check,
  Search,
  KeyboardArrowDown,
  Sort,
  KeyboardDoubleArrowDown,
} from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Paper,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
  Checkbox,
  Input,
} from "@mui/material";
import { AppBar, Modals } from "components";
import { Controller, useForm } from "react-hook-form";
import { ContentTable } from "./styles";
import { useMemo, useState, ChangeEvent, useEffect } from "react";
import { ICollaboratorUserViewModel } from "viewModels";
import { useAlert, useAuth } from "contexts";
import { ICollaboratorUserInputModel } from "inputModels";
import { CollaboratorUserController } from "controllers/CollaboratorUserController";
import { useCollaboratorUser } from "contexts/collaboratorUser";
import { EmailController } from "controllers/EmailController";
import { validateEmail } from "utils/validateEmail";
import { encryptPassword } from "utils/encryptPassword";
import { Columns } from "props";
import { ManagementStatus } from "types/enums";
import { generateRandomPassword } from "utils/generateRandomPassword";

const CollaboratorUser = () => {
  const alert = useAlert();
  const { user } = useAuth();
  const {
    orderBy,
    setOrder,
    hasNextPage,
    handleFetchNextPage,
    isFetchingNextPage,
    handleFetch,
  } = useCollaboratorUser();
  const [expectedCode, setExpectedCode] = useState("");
  const { collaboratorUsers, setCollaboratorUsers } = useCollaboratorUser();
  const [isLoading] = useState(false);
  const [isOpenEditUserModal, setIsOpenEditUserModal] = useState(false);
  const theme = useTheme();
  const [requesting] = useState(false);
  const [selectedCollaboratorUser, setSelectedCollaboratorUser] =
    useState<ICollaboratorUserViewModel>();
  const [managementStatus, setManagementStatus] = useState<ManagementStatus[]>([]);
  const [correspondencesFiltered, setCorrespondencesFiltered] =
    useState<ManagementStatus[]>();
  const [defaultValues] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    filter: "",
  });
  const {
    control,
    watch,
    clearErrors,
    setError,
    reset,
    register,
    formState: { errors },
  } = useForm({
    shouldFocusError: false,
    reValidateMode: "onChange",
    defaultValues: useMemo(() => defaultValues, [defaultValues]),
  });
  const data = watch();

// Supondo que o estado seja inicializado como um array

  const handleChange = (management: ManagementStatus) => {
    const currentStatus = managementStatus || [];
    console.log(management)
    console.log(currentStatus)

    if (currentStatus.includes(management)) {
      setManagementStatus(currentStatus.filter(status => status !== management));
    } else {
      setManagementStatus([...currentStatus, management]);
    }
  };

  const handleDisableCollaboratorUser = (id: string, status: boolean) => {
    CollaboratorUserController.updateDisableStatusCollaborator(id, status).then(
      () => {
        setCollaboratorUsers((prev) => {
          const newCollaboratorUsers = [...prev];
          const idx = newCollaboratorUsers.findIndex((item) => item.id === id);
          if (idx !== -1) {
            newCollaboratorUsers[idx] = {
              ...newCollaboratorUsers[idx],
              disabled: !newCollaboratorUsers[idx].disabled,
            };
          }
          return newCollaboratorUsers;
        });
      }
    );
  };

  const verifyEmail = async () => {
    let hasError = false;
    if (!user?.id) return;
    const alreadyEmail = await CollaboratorUserController.verifyAlreadyEmail(
      data.email
    );

    if (alreadyEmail.data) {
      setError("email", { message: "Este email já existe" });
      hasError = true;
    }

    if (!data.email) {
      setError("email", { message: "Informe o email" });
      hasError = true;
    }

    if (!data.name) {
      setError("name", { message: "Informe o nome" });
      hasError = true;
    }

    if (!validateEmail(data.email)) {
      setError("email", { message: "Email inválido" });
      hasError = true;
    }
    return hasError;
  };

  const handleCreateCollaboratorUser = async () => {
    clearErrors();
    if (!user?.id || (await verifyEmail())) return;

    const password = generateRandomPassword();
    const input: ICollaboratorUserInputModel = {
      createdById: user.id,
      name: data.name,
      email: data.email,
      password: encryptPassword(password),
      userId: user.id,
      managementStatus: managementStatus ,
    };
    CollaboratorUserController.insert(input)
      .then((res) => {
        const newCollaboratorUser: ICollaboratorUserViewModel = {
          id: res.data,
          name: input.name,
          email: input.email,
          createdAt: new Date(),
          createdByName: user.name,
          disabled: false,
          managementStatus: managementStatus ,
        };
        setCollaboratorUsers((prev) => [...prev, newCollaboratorUser]);
        setSelectedCollaboratorUser(newCollaboratorUser);
        reset();
        EmailController.send({
          to: newCollaboratorUser.email,
          subject: "Ativação de conta de colaborador",
          template: "template_email_value.html",
          replace: {
            SUBTITLE: `Olá!, ${user?.name ?? ""}`,
            VALUE: password,
            DESCRIPTION: `Utilize a senha abaixo para acessar sua conta de colaborador da empresa ${user.name} no Legal Control Audit:`,
            ICONURL:
              "https://vigna.adv.br/legalcontrol/legalhub/e-mail/secu.png",
          },
        });
        alert.show({
          type: "alert",
          title: "Sucesso:",
          description: "Usuário criado com sucesso!",
          timeout: 3000,
        });
        handleFetch();
      })
      .catch((error) => {
        if (error.response.data.errorCode === "EX00000") {
          setError("name", { message: "Nome já cadastrado" });
        } else {
          alert.show({
            type: "alert",
            title: "Falha:",
            description: "Houve um problema ao adicionar usuario.",
            timeout: 3000,
          });
        }
      });
  };

  const handleSort = (key: Columns) => () => {
    setOrder((prev) => {
      const newOrder = [...prev];
      const oldOrderIdx = prev.findIndex((o) => o.key === key);
      if (oldOrderIdx === -1) {
        newOrder.push({
          key,
          direction: "asc",
        });
      } else if (newOrder[oldOrderIdx].direction === "asc") {
        newOrder[oldOrderIdx].direction = "desc";
      } else {
        newOrder.splice(oldOrderIdx, 1);
      }
      return newOrder;
    });
  };

  const getSortIcon = (key: Columns) => (
    <IconButton size="small" onClick={handleSort(key)}>
      {orderBy.find((o) => o.key === key) ? (
        <KeyboardArrowDown
          sx={{
            transition: "all 0.5s ease-in",
            transform:
              orderBy.find((o) => o.key === key)?.direction === "asc"
                ? "rotate(-180deg)"
                : "",
          }}
        />
      ) : (
        <Sort />
      )}
    </IconButton>
  );

  return (
    <>
      <AppBar />
      <Container
        // maxWidth="lg"
        sx={{
          flexGrow: 1,
          minWidth: "90vw",
          minHeight: "100vh",
          overflowY: "auto",
          pt: 10,
          pb: 4,
        }}
      >
        <Paper sx={{ p: 5, mb: 5 }}>
          <Grid>
            <Typography variant="h4" color="primary" marginBottom={2}>
              Novo Usuário
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3.5}>
              {/* <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Nome"
                    placeholder="Informe o Nome"
                    error={!!errors.name?.message}
                    helperText={errors.email?.message}
                    {...field} 
                    // onChange={(e) => {
                    //   return e.target.value; // Retorna o valor do input
                    // }}
                  />
                )}
              /> */}
              <TextField
                fullWidth
                size="small"
                label="Nome"
                error={!!errors.name}
                helperText={errors.name?.message}
                placeholder="Informe o Nome"
                {...register('name', {
                  required: 'O nome é obrigatório',
                })}
              />
              </Grid>
              <Grid item xs={12} sm={6} md={3.5}>
                <TextField
                  fullWidth
                  size="small"
                  label="E-mail"
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  placeholder="Informe o E-mail"
                  {...register('email', {
                    required: 'O e-mail é obrigatório',
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: 'O e-mail deve ser válido',
                    }
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Grid display="flex" justifyContent="center" gap={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked= {
                          managementStatus.includes(ManagementStatus.MODEL)
                        } 
                        onChange={() => {
                          handleChange(ManagementStatus.MODEL)
                        }
                      }
                      />
                    }
                    label="Gerenciar Modelos"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          managementStatus.includes(ManagementStatus.USER)
                        }
                        onChange={() => {
                          handleChange(ManagementStatus.USER)
                        }
                        }
                      />
                    }
                    label="Gerenciar Usuários"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          managementStatus.includes(ManagementStatus.PODEN)
                        }
                        onChange={() => {
                          handleChange(ManagementStatus.PODEN)
                        }
                        }
                      />
                    }
                    label="Gerenciar Podenradores"
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={2}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  style={{ width: "12rem" }}
                  type="submit"
                  variant="contained"
                  sx={{ cursor: requesting ? "wait" : "pointer" }}
                  onClick={() => {
                    handleCreateCollaboratorUser();
                  }}
                >
                  <Typography variant="button">Salvar</Typography>
                  {requesting && (
                    <CircularProgress
                      size={15}
                      sx={{ ml: 1, color: "white" }}
                    />
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <ContentTable>
          <Grid display="flex" justifyContent="end" margin="0.5rem 0">
            <Controller
              control={control}
              name="filter"
              render={({ field: { onChange, value } }) => (
                <TextField
                  size="small"
                  label="Pesquisar"
                  variant="standard"
                  type="search"
                  placeholder="O que deseja buscar?"
                  sx={{ width: 250 }}
                  onChange={onChange}
                  value={value}
                  InputProps={{
                    sx: {
                      pl: 1,
                    },
                    endAdornment: <Search />,
                  }}
                />
              )}
            />
          </Grid>
          <Table stickyHeader aria-label="listModels">
            <TableHead>
              <TableRow
                sx={{
                  th: {
                    backgroundColor: "white",
                    "& p": {
                      fontWeight: "bold",
                      color: theme.palette.primary.main,
                    },
                    "& div": {
                      display: "flex",
                      alignItems: "center",

                      "& button": {
                        ml: 1,
                      },
                    },
                  },
                }}
              >
                <TableCell>
                  <Grid>
                    <Typography>Nome</Typography>
                    {getSortIcon("name")}
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid>
                    <Typography>Email</Typography>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid>
                    <Typography>Data de criação</Typography>
                    {getSortIcon("date")}
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid>
                    <Typography>Criado por</Typography>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid>
                    <Typography>Editar</Typography>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Typography>Habilitado</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <Grid
                      item
                      alignItems="center"
                      sx={{ minHeight: "65px !important" }}
                    >
                      <CircularProgress size={15} />
                      <Typography
                        paddingLeft={1}
                        variant="caption"
                        color="text"
                        sx={{ fontStyle: "italic" }}
                      >
                        Carregando lista de modelos...
                      </Typography>
                    </Grid>
                  </TableCell>
                </TableRow>
              ) : !collaboratorUsers?.length ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <Typography
                      paddingLeft={1}
                      variant="caption"
                      color="text"
                      sx={{ fontStyle: "italic" }}
                    >
                      Nenhum usuário encontrado...
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                collaboratorUsers
                  .filter((m) =>
                    data.filter
                      ? m.name
                          .toLowerCase()
                          .includes(data.filter.toLowerCase()) ||
                        m.email
                          .toLowerCase()
                          .includes(data.filter.toLowerCase()) ||
                        new Date(m.createdAt)
                          .toLocaleDateString("pt-BR", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          })
                          .includes(data.filter)
                      : collaboratorUsers
                  )
                  .map((item) => (
                    <TableRow key={item.id} data-testid="rowUsers">
                      <TableCell>
                        <Typography>{item.name}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{item.email}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {new Date(item.createdAt).toLocaleDateString(
                            "pt-BR",
                            {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{item.createdByName}</Typography>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => {
                            setIsOpenEditUserModal(true);
                            setSelectedCollaboratorUser(item);
                          }}
                        >
                          <Edit color="primary" />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => {
                            handleDisableCollaboratorUser(
                              item.id,
                              !item.disabled
                            );
                          }}
                        >
                          {item.disabled ? (
                            <DoDisturb color="error" />
                          ) : (
                            <Check color="success" />
                          )}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
              )}
              {hasNextPage && (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    {isFetchingNextPage ? (
                      <Grid
                        item
                        alignItems="center"
                        sx={{ minHeight: "65px !important" }}
                      >
                        <CircularProgress size={15} />
                        <Typography
                          paddingLeft={1}
                          variant="caption"
                          color="text"
                          sx={{ fontStyle: "italic" }}
                        >
                          Carregando itens...
                        </Typography>
                      </Grid>
                    ) : (
                      <Button
                        onClick={() => handleFetchNextPage()}
                        sx={{ px: 2 }}
                      >
                        <KeyboardDoubleArrowDown />
                        <Typography paddingLeft={2} variant="button">
                          Carregar mais...
                        </Typography>
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </ContentTable>
        <Modals.EditCollaboratorUserModal
          open={isOpenEditUserModal}
          onCodeChange={(code) => setExpectedCode(code)}
          dataCode={{
            codeExpected: expectedCode,
            contactLabel: "E-mail",
            contactValue: data.email,
          }}
          collaboratorUser={
            selectedCollaboratorUser || ({} as ICollaboratorUserViewModel)
          }
          onClose={() => setIsOpenEditUserModal(false)}
        />
      </Container>
    </>
  );
};

export default CollaboratorUser;
