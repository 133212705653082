import { IMessageAssistantModel } from "./../types/entities.d";
import { api } from "services";

const sendAssistantMessage = (message: IMessageAssistantModel) => {
  return new Promise<IMessageAssistantModel>((resolve, reject) => {
    api
      .post(`/conversation/send-assistant-message`, message)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};

const ConversationController = {
  sendAssistantMessage,
};

export default ConversationController;
