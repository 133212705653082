export enum DossierStatus {
  PENDING = 0,
  PROCESSING = 1,
  DONE = 2,
  CANCELED = 3,
  PROCESSING_ERROR = 4,
}

export enum DossierPdfStatus {
  PENDING = 0,
  BUILDING = 1,
  DONE = 2,
  CANCELED = 3,
}

export enum RecklessTraderRisk {
  LOW = 0,
  MEDIUM = 1,
  HIGH = 2,
}

export enum ManagementStatus {
  MODEL,
  USER,
  PODEN,
}

export enum ProcessStatus {
  PENDING = 0,
  PROCESSING = 1,
  DONE = 2,
  CANCELED = 3,
  PROCESSING_ERROR = 4,
}
export enum LogStatus {
  PENDING = 0,
  RESOLVED = 1,
  UNSOLVED = 2,
  UNREQUESTED = 3,
}

export enum NotificationType {
  OTHER = 0,
  NEW_DOSSIER = 1,
  UPDATE_PDF_STATUS = 2,
  UPDATE_DOSSIER_STATUS = 3,
}

export enum SearchType {
  CPF = 0,
  CNPJ = 1,
  BOTH = 2,
  DISABLED = 3,
}

export enum GenerateAgainType {
  WEEKLY = 0,
  MONTHLY = 1,
  EVERY_SIX_MONTHS = 2,
  CUSTOM = 3,
}
export enum TypeUser {
  USER = 0,
  COLLABORATOR = 1,
  ADM = 2,
}
export enum Gender {
  MALE = 0,
  FEMALE = 1,
}
export const LogStatusText = {
  [LogStatus.PENDING]: "Pendente",
  [LogStatus.RESOLVED]: "Resolvido",
  [LogStatus.UNSOLVED]: "Falha",
  [LogStatus.UNREQUESTED]: "Não solicitado",
};

export enum GetSolicitationFilterDateType {
  FROM_START,
  FROM_EXPIRATION,
}

export enum ImportType {
  FINANCIAL,
}

export enum Role {
  ASSISTANT,
  USER,
  SYSTEM,
}

export enum TransactionType {
  CREDIT,
  DEBIT,
}

export enum CurrencyType {
  USD, // Dólar Americano
  EUR, // Euro
  BRL, // Real Brasileiro
  GBP, // Libra Esterlina
  JPY, // Iene Japonês
  AUD, // Dólar Australiano
  CAD, // Dólar Canadense
}

export enum PaymentType {
  CREDIT_CARD, // Cartão de Crédito
  DEBIT_CARD, // Cartão de Débito
  PIX, // Pix
  BOLETO, // Boleto Bancário
  BANK_TRANSFER, // Transferência Bancária
  PAYPAL, // PayPal
  STRIPE, // Stripe
}

export enum PaymentStatus {
  PENDING, // Pagamento Pendente
  COMPLETED, // Pagamento Concluído
  FAILED, // Pagamento Falhou
  CANCELLED, // Pagamento Cancelado
  REFUNDED, // Pagamento Reembolsado
  PROCESSING, // Pagamento em Processamento
  EXPIRED, // Pagamento Expirado
}
