import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .Mui-focused {
    color: ${({ theme }) => theme.colors.text};
    font-size: 1.3rem;
  }

  .MuiInputBase-root {
    &::before {
      border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
    }
  }
  .MuiInput-input {
    color: ${({ theme }) => theme.colors.text};
    font-size: 1.3rem;
    padding: 0.2rem 0;
  }
  .MuiOutlinedInput-root {
    padding: 0;
    color: ${({ theme }) => theme.colors.text};
  }
  .MuiOutlinedInput-input {
    padding: 0.6rem 1rem;
  }
  .iconBtn {
    background: transparent;
    border: none;
    svg {
      color: ${({ theme }) => theme.colors.primary};
      font-size: 1.3rem;
    }
  }

  .fieldError {
    color: red;
    display: flex;
    align-items: center;
    font-size: 1rem;
    flex-wrap: nowrap;
    svg {
      margin-right: 2px;
      font-size: 1rem;
      padding-bottom: 0.1rem;
    }
  }
`;
