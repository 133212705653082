import styled from "styled-components";

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const Steps = styled.div`
  width: 100%;
  margin: 10px 0 10px 0;
`;

export const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  flex-grow: 1;
  overflow-y: auto;
`;

export const Title = styled.p`
  padding: 10px;
  font-size: large;
  color: ${({ theme }) => theme.colors.text_grey};
  text-align: center;
`;
export const PaymentResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: center;
`;

export const StatusMessage = styled.p`
  font-size: large;
  color: ${({ theme }) => theme.colors.text_grey};
`;

export const PaymentMessage = styled.p`
  font-size: medium;
  color: ${({ theme }) => theme.colors.text_grey};
  text-align: center;
  padding: 10px;
`;

export const PaymentId = styled.p`
  font-size: small;
  color: ${({ theme }) => theme.colors.text_grey};
`;
