import axios from 'axios';
import { IConsultCnpj } from 'types/entities';
import { convertConsultCnpjResponseToConsultCnpj } from 'utils/convertConsultCnpjResponseToConsultCnpj';

export const getByCnpj = async (cnpj: string) => {
  return (await axios
    .get(`https://brasilapi.com.br/api/cnpj/v1/${cnpj}`)
    .then(({ data }) => convertConsultCnpjResponseToConsultCnpj(data))) as IConsultCnpj;
    
};
