import { Cancel, LabelImportant } from "@mui/icons-material";
import { Container, Grid, IconButton, ListItem, ListItemIcon, ListItemText, Typography, useTheme } from "@mui/material";
import { Modal } from "components";
import { ModalProps } from "..";
import { IAvailableExternalFontViewModel } from "viewModels";

interface FontsDossierModelModalProps extends ModalProps {
  externalFonts: IAvailableExternalFontViewModel[];
}

const FontsDossierModelModal: React.FC<FontsDossierModelModalProps> = ({
  externalFonts,
  ...props
}: FontsDossierModelModalProps) => {
  const theme = useTheme();
  return (
    <Modal
      {...props}
      sx={{
        position: "relative",
        width: "24rem",
        height: "34rem",
        overflowX: "hidden",
        overflowY: "auto",
      }}
    >
      <IconButton
        size="small"
        onClick={props?.onClose}
        color="error"
        sx={{
          position: "absolute",
          top: "5px",
          right: "5px",
        }}
      >
        <Cancel />
      </IconButton>
      <Container >
        <Typography variant="h5" color="primary" marginBottom={2}>Fontes</Typography>
        <Grid container columns={{ xs: 12, sm: 8, md: 4 }} height="26rem" overflow="auto" border={`1px solid ${theme.palette.primary.light}`} borderRadius="6px" borderColor="primary">
          {externalFonts.length ? (
            externalFonts.map((item) => (
              <ListItem
                key={item.key}
                sx={{
                  transition: "all 0.5s ease-in-out",
                  cursor: "pointer",
                  borderRadius: 1,
                  userSelect: "none",
                  ":hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.05)",
                  },
                }}
              >
                <ListItemIcon sx={{ minWidth: 30 }}>
                  <LabelImportant
                    color="warning"
                  />
                </ListItemIcon>
                <ListItemText primary={item.value.name} />
              </ListItem>
            ))

          ) : (
            <Grid margin={1}>
              <Typography color="primary" >Não existe nenhuma fonte selecionada.</Typography>
            </Grid>
          )}
        </Grid>
      </Container>
    </Modal >
  );
};

export default FontsDossierModelModal;


