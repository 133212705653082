import styled from "styled-components";

export const BalanceContainer = styled.div`
  .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .balanceContent {
    display: flex;
    justify-content: space-between;
    align-items: end;
  }
  .addCreditButton {
    max-height: 40px;
    margin: 10px;
  }

  .balanceValue {
    display: flex;
    align-items: flex-start;
    margin-left: 10px;
  }
`;
