import { ModelContextProps } from "contexts-types";
import { ModelDossierController } from "controllers/ModelDossierController";
import { IOrderFieldInputModel } from "inputModels";
import { Columns, FilterProps, Props } from "props";
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useInfiniteQuery } from "react-query";
import { IModelDossierViewModel } from "viewModels";
import { useAuth } from "./auth";

const PAGE_SIZE = 15;
const ModelContext = createContext<ModelContextProps>({} as ModelContextProps);

const Model: React.FC<Props> = ({ children }) => {
  const { user, logged } = useAuth();
  const [models, setModels] = useState<IModelDossierViewModel[]>([]);
  const [orderBy, setOrder] = useState<IOrderFieldInputModel<Columns>[]>([]);
  const [filter, setFilter] = useState<FilterProps>();
  const {
    data: results,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery(
    ["modelsDossier", orderBy, filter],
    ({ pageParam = 1 }) =>
      ModelDossierController.getByModelId(
        {
          orderBy,
          page: pageParam,
          take: 15,
          term: filter?.term,
          filter: filter?.term ? filter?.filter : undefined,
        }
      ).then((res) => res.data),
    {
      enabled: !!user?.id && logged,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1;
        return lastPage.length < PAGE_SIZE ? undefined : nextPage;
      },
    },
  );

  const handleFetch = () => {
    refetch();
  };

  useEffect(() => {
    setModels(results?.pages?.flat() ?? []);
  }, [results, setModels]);

  const handleFetchNextPage = () => {
    fetchNextPage();
  };

  return (
    <ModelContext.Provider
      value={{
        orderBy,
        filter,
        setFilter,
        models,
        setModels,
        setOrder,
        handleFetchNextPage,
        isFetchingNextPage,
        hasNextPage,
        handleFetch,
      }}
    >
      {children}
    </ModelContext.Provider>
  );
};

export const useModel: () => ModelContextProps = () => useContext(ModelContext);

export default Model;
