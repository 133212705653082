import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import Modal, { ModalProps } from "..";
import { Cancel } from "@mui/icons-material";
import { IDossierViewModel } from "viewModels";
import { Container } from "./styles";

interface SummaryDossierProps extends ModalProps {
  dossier: IDossierViewModel;
}

const SummaryDossier: React.FC<SummaryDossierProps> = ({
  dossier,
  ...props
}: SummaryDossierProps) => {
  const theme = useTheme();
  return (
    <Modal
      {...props}
      data-testid="generate-again-mock"
      sx={{
        position: "relative",
        width: "70rem",
        height: "70rem",
        maxHeight: "86vh",
        overflowX: "hidden",
        overflowY: "auto",
        p: 1,
        "@media (max-width: 375px)": {
          width: "23rem",
          maxHeight: "100vh",
          height: "50rem",
        },
      }}
    >
      <Grid container justifyContent="space-between" padding="0.5rem 0">
        <Typography variant="h4" component="h2" color="primary">
          Resumo
        </Typography>
        <IconButton size="small" onClick={props.onClose} color="error">
          <Cancel />
        </IconButton>
      </Grid>
      <Container>
        <Table stickyHeader aria-label="listDossiers">
          <TableHead>
            <TableRow
              sx={{
                th: {
                  backgroundColor: "white",
                  "& p": {
                    fontWeight: "bold",
                    color: theme.palette.primary.main,
                  },
                  "& div": {
                    display: "flex",
                    alignItems: "center",

                    "& button": {
                      ml: 1,
                    },
                  },
                },
              }}
            >
              <TableCell>
                <Typography>Fontes Pesquisadas</Typography>
              </TableCell>
              <TableCell>
                <Grid>
                  <Typography>Status</Typography>
                </Grid>
              </TableCell>
              <TableCell>
                <Grid>
                  <Typography>Pendências</Typography>
                </Grid>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dossier.summaryDossier?.namesFontsWithPendenciesName?.map(
              (font) => (
                <TableRow
                  data-testid="tableRowDossier"
                  key={font.name}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell>
                    <Tooltip title={font.name} placement="top-start">
                      <Typography>{font.name}</Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Typography>Ok</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      style={{
                        color: theme.palette.warning.light,
                      }}
                    >
                      Sim
                    </Typography>
                  </TableCell>
                </TableRow>
              )
            )}
            {dossier.summaryDossier?.namesFontsWithoutPendenciesName?.map(
              (font) => (
                <TableRow
                  data-testid="tableRowDossier"
                  key={font.name}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell>
                    <Tooltip title={font.name} placement="top-start">
                      <Typography>{font.name}</Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Typography>Ok</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      style={{
                        color: theme.palette.success.light,
                      }}
                    >
                      Não
                    </Typography>
                  </TableCell>
                </TableRow>
              )
            )}
            {dossier.summaryDossier?.namesFontsWithoutData?.map((font) => (
              <TableRow
                data-testid="tableRowDossier"
                key={font.name}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell>
                  <Tooltip title={font.name} placement="top-start">
                    <Typography>{font.name}</Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Typography
                    style={{
                      color: theme.palette.error.light,
                    }}
                  >
                    Erro
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>-</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Container>
    </Modal>
  );
};

export default SummaryDossier;
