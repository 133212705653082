import styled from "styled-components";

export const ContentTable = styled.div`
  background-color: white;
  max-height: 50.5vh;
  overflow-x: auto;
  text-overflow: ellipsis;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
    rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  width: 100%;
  overflow-x: auto;
  padding-left: 2.5rem;
  padding-right: 2.5rem;

  @media (min-width: 320px) and (max-width: 599px) {
    max-height: 34vh;
    white-space: nowrap;
  }

  @media (min-width: 600px) and (max-width: 899px) {
    max-height: 32vh;
    white-space: nowrap;
  }
`;
