import styled from "styled-components";

export const Container = styled.div`
    height: 320px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    gap: 10px;
    margin-left: 1rem;
    p, li{
        margin-left: 1.2rem;
    }
    ul{
        margin-left: 2.3rem;
    }
    p{
        text-indent: 2.3rem;
    }
    span{
        text-decoration: underline;
    }
    thead{
        background: #c55e19;
    }
    tbody th{
        background: #e9e9e9;
    }
    table, th, td {
        border: 1px solid black;
        border-collapse: collapse;
    }
    td{
        height: 100%;
        width: 50%;
        & div{
            padding: 0.3rem;
            width: 100%;
            height: 100%;
            border-top: 1px solid black;
            border-collapse: collapse;
        }
        p, li{
            margin-left: 0;
        }
        ul{
            margin-left: 1.5rem;
        }
    }
`;
