import { ITransactionViewModel } from "viewModels";
import { Modal } from "components";
import {
  HeaderAmount,
  ModalContent,
  ModalHeader,
  TransactionDetails,
} from "./styles";
import { Button, IconButton, Typography } from "@mui/material";
import { Cancel } from "@mui/icons-material";

import {
  CurrencyType,
  PaymentStatus,
  PaymentType,
  TransactionType,
} from "types/enums";
import html2canvas from "html2canvas";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import CancelIcon from "@mui/icons-material/Cancel";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import ReplayCircleFilledIcon from "@mui/icons-material/ReplayCircleFilled";
import CircleIcon from "@mui/icons-material/Circle";
import { useRef } from "react";

interface TransactionDetailsModalProps {
  transaction: ITransactionViewModel;
  onClose: () => void;
  open: boolean;
}

function TransactionDetailsModal({
  onClose,
  open,
  transaction,
}: TransactionDetailsModalProps) {
  const modalRef = useRef<HTMLDivElement>(null);

  const PaymentTypeDescriptions: Record<PaymentType, string> = {
    [PaymentType.CREDIT_CARD]: "CARTÃO DE CRÉDITO",
    [PaymentType.DEBIT_CARD]: "CARTÃO DE DÉBITO",
    [PaymentType.PIX]: "PIX",
    [PaymentType.BOLETO]: "BOLETO BANCÁRIO",
    [PaymentType.BANK_TRANSFER]: "TRANSFERÊNCIA BANCÁRIA",
    [PaymentType.PAYPAL]: "PAYPAL",
    [PaymentType.STRIPE]: "STRIPE",
  };

  const CurrencyTypeDescriptions: Record<CurrencyType, string> = {
    [CurrencyType.USD]: "Dólar Americano",
    [CurrencyType.EUR]: "Euro",
    [CurrencyType.BRL]: "Real Brasileiro",
    [CurrencyType.GBP]: "Libra Esterlina",
    [CurrencyType.JPY]: "Iene Japonês",
    [CurrencyType.AUD]: "Dólar Australiano",
    [CurrencyType.CAD]: "Dólar Canadense",
  };

  const PaymentStatusDescriptions: Record<PaymentStatus, string> = {
    [PaymentStatus.PENDING]: "PENDENTE",
    [PaymentStatus.COMPLETED]: "CONCLUÍDO",
    [PaymentStatus.FAILED]: "FALHA",
    [PaymentStatus.CANCELLED]: "CANCELADO",
    [PaymentStatus.REFUNDED]: "REEMBOLSADO",
    [PaymentStatus.PROCESSING]: "PROCESSANDO",
    [PaymentStatus.EXPIRED]: "EXPIRADO",
  };

  const getStatusIcon = (status: PaymentStatus) => {
    switch (status) {
      case PaymentStatus.COMPLETED:
        return <CheckCircleIcon className="icon" sx={{ color: "#3fbd45" }} />;
      case PaymentStatus.PENDING:
        return <WatchLaterIcon className="icon" sx={{ color: "#faa628" }} />;
      case PaymentStatus.FAILED:
        return <DoDisturbOnIcon className="icon" sx={{ color: "#fa0c04" }} />;
      case PaymentStatus.CANCELLED:
        return <CancelIcon className="icon" sx={{ color: "#fa0c04" }} />;

      case PaymentStatus.REFUNDED:
        return (
          <ReplayCircleFilledIcon className="icon" sx={{ color: "#faa628" }} />
        );
      case PaymentStatus.PROCESSING:
        return <WatchLaterIcon className="icon" sx={{ color: "#faa628" }} />;
      case PaymentStatus.EXPIRED:
        return <WatchLaterIcon className="icon" sx={{ color: "#fd7672" }} />;
      default:
        return <CircleIcon className="icon" sx={{ color: "#797979" }} />;
    }
  };

  const handleExport = () => {
    if (modalRef.current) {
      html2canvas(modalRef.current).then((canvas) => {
        const link = document.createElement("a");
        link.download = "transaction-details.png";
        link.href = canvas.toDataURL();
        link.click();
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        minWidth: "400px",
        minHeight: "70vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ModalHeader>
        <Typography variant="h6" component="h2" color={"primary"}>
          Detalhes da transação
        </Typography>
        <IconButton size="small" onClick={onClose} color="error">
          <Cancel />
        </IconButton>
      </ModalHeader>
      <ModalContent ref={modalRef}>
        <HeaderAmount>
          <span className="amount-title">VALOR</span>
          <div className="amount">
            <span className="prefix">R$</span>
            <span className="value">
              {(transaction.amount / 100).toFixed(2)}
            </span>
          </div>
          <span className="amount-title">
            {new Date(transaction.createdAt)
              .toLocaleString("pt-BR", {
                day: "2-digit",
                month: "long",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })
              .replace(",", " às")}
            {"  "}
            (BRT)
          </span>
        </HeaderAmount>
        <TransactionDetails>
          <span className="details-title">DETALHES</span>
          <div className="list-item">
            <span className="key">Valor</span>
            <span className="value">
              R$ {(transaction.amount / 100).toFixed(2)}
            </span>
          </div>
          <div className="list-item">
            <span className="key">ID da tansação</span>
            <span className="value">{transaction.id}</span>
          </div>
          {transaction.transactionCode && (
            <div className="list-item">
              <span className="key">Código de controle</span>
              <span className="value">{transaction.transactionCode}</span>
            </div>
          )}

          <div className="list-item">
            <span className="key">Tipo</span>
            {transaction.transactionType === TransactionType.CREDIT ? (
              <span className="value">CRÉDITO</span>
            ) : (
              <span className="value">DÉBITO</span>
            )}
          </div>
          <div className="list-item">
            <span className="key">Método de pagamento</span>
            <span className="value">
              {PaymentTypeDescriptions[transaction.paymentMethod]}
            </span>
          </div>

          <div className="list-item">
            <span className="key">Moeda</span>
            <span className="value">
              {CurrencyTypeDescriptions[transaction.currency]}
            </span>
          </div>

          <div className="list-item">
            <span className="key">Data</span>
            <span className="value">
              {new Date(transaction.createdAt).toLocaleString("pt-BR", {
                day: "2-digit",
                month: "long",
                year: "numeric",
              })}
            </span>
          </div>
          <div className="list-item">
            <span className="key">Horário</span>
            <span className="value">
              {new Date(transaction.createdAt)
                .toLocaleString("pt-BR", {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                })
                .replace(",", " às")}
              {"  "}
              (BRT)
            </span>
          </div>
          <div className="list-item">
            <span className="key">Status</span>
            <span className="value">
              <div
                style={{
                  alignContent: "center",
                  alignItems: "baseline",
                  justifyContent: "center",
                }}
              >
                {getStatusIcon(transaction.status)}
                {PaymentStatusDescriptions[transaction.status]}
              </div>
            </span>
          </div>
        </TransactionDetails>
      </ModalContent>

      <Button
        variant="contained"
        sx={{ height: 40, alignItems: "center" }}
        onClick={handleExport}
      >
        Exportar
      </Button>
    </Modal>
  );
}

export default TransactionDetailsModal;
