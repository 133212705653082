import api from "services/api";
import {
  IRecklessTraderReportViewModel,
  IRecklessTraderViewModel,
} from "viewModels";
import {
  ICreateRecklessInputModel,
  IRecklessTraderReportInputModel,
} from "inputModels";

const GetAll = () =>
  new Promise<IRecklessTraderViewModel[]>((resolve, reject) =>
    api
      .get("recklesstrader/get-all")
      .then(({ data: { data } }) => resolve(data))
      .catch(reject)
  );

const Insert = (input: ICreateRecklessInputModel) =>
  new Promise<string>((resolve, reject) =>
    api
      .post("recklesstrader/", input)
      .then(({ data: { data } }) => resolve(data))
      .catch(reject)
  );

const Update = (input: ICreateRecklessInputModel) =>
  new Promise<string>((resolve, reject) =>
    api
      .put("recklesstrader/", input)
      .then(({ data }) => resolve(data))
      .catch(reject)
  );

const Disabled = (id: string) =>
  new Promise<boolean>((resolve, reject) =>
    api
      .get(`recklesstrader/disabled/${id}`)
      .then(({ data }) => resolve(data))
      .catch(reject)
  );

const InsertReport = (recklessTradersReport: IRecklessTraderReportInputModel) =>
  new Promise<string>((resolve, reject) =>
    api
      .post(`recklesstrader/reports`, recklessTradersReport)
      .then(({ data: { data } }) => resolve(data))
      .catch(reject)
  );

const GeneratePdf = (id: string) =>
  new Promise<string>((resolve, reject) =>
    api
      .get(`recklesstrader/reports/${id}`)
      .then(({ data: { data } }) => resolve(data))
      .catch(reject)
  );

const download = (id: string, type: "pdf" | "zip" = "pdf") =>
  new Promise<string[]>((resolve, reject) => {
    api
      .get(`recklesstrader/download/${id}`, {
        params: { type },
      })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const GetAllReports = () =>
  new Promise<IRecklessTraderReportViewModel[]>((resolve, reject) =>
    api
      .get(`recklesstrader/reports-get-all`)
      .then(({ data }) => resolve(data.data))
      .catch(reject)
  );

export const RecklessTraderController = {
  GetAll,
  Insert,
  Update,
  Disabled,
  InsertReport,
  GeneratePdf,
  GetAllReports,
  download,
};
