import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`	

* {
  ::-webkit-scrollbar {
      width: 8px;
      background-color: ${({ theme }) => theme.colors.background_white};
      border-radius: 10px;
    }
    ::-webkit-scrollbar:horizontal {
      height: 5px;
    }
    ::-webkit-scrollbar-track,
    ::-webkit-scrollbar-track-horizontal {
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb,
    ::-webkit-scrollbar-thumb:horizontal {
      background-color: ${({ theme }) => theme.colors.primary};
      border-radius: 10px;
    }
    font-family: "Futura PT", "Roboto", sans-serif !important;
}

html{
    
    overflow-x: hidden;

    @media (max-width: 1080px){
        font-size: 93.75%;
    }

    @media (max-width: 720px){
    
        font-size: 87.5%;
    }

    body,p,h1,h2,h3{
        margin: 0;
    } 

    button{
        cursor:pointer;
        font-family: 'Futura PT'!important;
    }

    .displayNone{
        display: none;
    } 
}`;
