import styled from "styled-components";

export const Container = styled.div<{ backgroundColor?: string }>`
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  /* align-items: center;
  justify-content: center; */
  position: relative;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor || theme.colors.primary};
  overflow: hidden;
  flex: 1 1 calc(20% - 40px);
  min-width: 200px;
  max-width: 300px;

  .icon {
    position: absolute;
    color: rgba(255, 255, 255, 0.3);
    font-size: 150px;
    bottom: -30px;
    right: -30px;
    z-index: 0;
  }
`;

export const Title = styled.span`
  font-size: 16px;
  color: white;
  z-index: 1;
`;

export const Value = styled.span`
  font-size: 45px;
  color: white;
  z-index: 1;
`;

export const Footer = styled.div`
  display: flex;
  align-items: baseline;
  color: ${({ theme }) => theme.colors.text};
  .diff {
    font-size: 16px;
  }

  .period {
    font-size: 10px;
  }

  svg {
    margin-right: 5px;
  }
`;
