// eslint-disable-next-line import/no-anonymous-default-export
export default {
  title: "light",
  colors: {
    primary: "#293778",
    secondary: "#004056",
    background: "#F1F1F1",
    background_white: "#fff",
    text: "#707070",
    red_200: "#ffe5e5",
    red: "#B10000",
    green: "#e5ffee",
    green_500: "#74CEB7",
    green_300: "#C9FFd5",
    yellow: "#ffffcb",
    text_grey: "#8A8A8A",
    grey: "#8D8D8D",
    grey_200: "#F8F8F8",
    grey_300: "#5A5A5A",
    yellow_200: "#FFFFCB",
    blue_200: "#BAEBFE",
    green_primary: "#2C858D",
  },
};
