import { IOrderedWithPaginationInputModel } from 'inputModels';
import api from 'services/axios';
import { ILogUserViewModel, IResponse } from 'viewModels';

const download = (data: any, name: string) => {
  const blob = new Blob([data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const uri = window.URL.createObjectURL(blob);

  if (!uri) return;
  const link = document.createElement('a');

  link.href = uri;
  link.setAttribute('download', name);
  link.rel = 'noopener noreferrer';
  link.referrerPolicy = 'no-referrer';
  link.click();

  link.parentElement?.removeChild(link);
  window.URL.revokeObjectURL(uri);
};

export const exportReportFinancialFonts = () => {
  return new Promise<void>((resolve, reject) => {
    api
      .get(`report/export-report-financial-fonts`, {
        responseType: 'blob',
      })
      .then(({ data }) => {
        download(data, `fontes-${Date.now()}`); 
        resolve();
      })
      .catch(reject);
  });
};

export const listAll = <T>(input?: IOrderedWithPaginationInputModel<T>) =>
  new Promise<IResponse<ILogUserViewModel[]>>((resolve, reject) => {
    api
      .get(
        `report/get-all?page=${input?.page}&take=${input?.take}${input?.term ? `&term=${input?.term}` : ""
        }${input?.filter ? `&filter=${input?.filter}` : ""}&${input?.orderBy
          ?.map(
            (o, i) =>
              `orderBy[${i}].key=${o.key}&orderBy[${i}].direction=${o.direction}`
          )
          .join("&")}`
      )
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

