import React, { useState } from "react";

import { AppBar } from "components";
import {
  Container,
  Paper,
  Typography,
  Button,
  Skeleton,
  CircularProgress,
} from "@mui/material";
import { BalanceContainer } from "./styles";
import AddCreditModal from "components/Modal/AddCreditModal";
import { usePayments } from "contexts/payment";
import TransactionTimeline from "components/TransactionTimeline";
import KpiCard from "components/KpiCard";

import WalletOutlinedIcon from "@mui/icons-material/WalletOutlined";
import InsightsIcon from "@mui/icons-material/Insights";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import TuneIcon from "@mui/icons-material/Tune";
import LineChart from "components/LineChart";
import TransactionDetailsModal from "components/Modal/TransactionDetailsModal";
import { ITransactionViewModel } from "viewModels";
import { Empty } from "antd";

const Balance: React.FC = () => {
  const [showModalAddCredit, setShowModalAddCredit] = useState<boolean>(false);
  const [showTransactionDetails, setShowTransactionDetails] =
    useState<boolean>(false);
  const [currentTransaction, setCurrentTransaction] =
    useState<ITransactionViewModel>();
  const {
    transactions,
    cardsData,
    financialHistoryData,
    loadingCards,
    loadingHistory,
    isFetching,
    isFetchingNextPage,
    handleFetchNextPage,
    hasNextPage,
  } = usePayments();

  const handleTransactionDetails = (transaction: ITransactionViewModel) => {
    setCurrentTransaction(transaction);
    setShowTransactionDetails(true);
  };

  return (
    <>
      <AppBar />
      <Container
        sx={{
          minWidth: "90vw",
          minHeight: "100vh",
          pt: 10,
          pb: 4,
        }}
      >
        <Paper sx={{ p: 5, mb: 5 }}>
          <Typography component="p" variant="h4" color="primary">
            Balanço Financeiro
          </Typography>
          {!loadingCards ? (
            <BalanceContainer>
              <div className="cards">
                <KpiCard
                  title="Saldo Atual"
                  value={cardsData?.balance ? cardsData?.balance / 100 : 0}
                  currency
                  icon={<WalletOutlinedIcon />}
                />
                <KpiCard
                  title="Transações"
                  value={cardsData?.totalTransactions ?? 0}
                  icon={<TuneIcon />}
                  backgroundColor="#af1a58"
                />
                <KpiCard
                  title="Consumo mensal"
                  value={cardsData?.currentMonthSpent ?? 0}
                  currency
                  icon={<ReceiptLongIcon />}
                  backgroundColor="#bd8102"
                />

                <KpiCard
                  title="Consumo Diferencial Mensal"
                  value={cardsData?.monthDifference ?? 0}
                  percent
                  icon={<InsightsIcon />}
                  backgroundColor="#107066"
                />
              </div>

              <div className="balanceContent">
                <Button
                  className="addCreditButton"
                  variant="contained"
                  color="primary"
                  onClick={() => setShowModalAddCredit(true)}
                >
                  Adicionar Saldo
                </Button>
              </div>
            </BalanceContainer>
          ) : (
            <BalanceContainer>
              <div className="cards">
                {Array.from({ length: 4 }).map((_, index) => (
                  <Skeleton
                    key={index}
                    sx={{
                      height: 110,
                      minWidth: "200px",
                      maxWidth: "300px",
                      margin: "10px",
                      flex: "1 1 calc(20% - 40px)",
                    }}
                    animation="wave"
                    variant="rectangular"
                  />
                ))}
              </div>
              <div className="balanceContent">
                <Button
                  className="addCreditButton"
                  variant="contained"
                  color="primary"
                  onClick={() => setShowModalAddCredit(true)}
                >
                  Adicionar Saldo
                </Button>
              </div>
            </BalanceContainer>
          )}
        </Paper>

        <Paper sx={{ p: 5, mb: 5 }}>
          <Typography component="p" variant="h4" color="primary">
            Total de transações por mês
          </Typography>
          {!loadingHistory ? (
            financialHistoryData && <LineChart data={financialHistoryData} />
          ) : (
            <Skeleton
              sx={{ height: 350 }}
              animation="wave"
              variant="rectangular"
            />
          )}
        </Paper>

        <Paper sx={{ p: 5, mb: 5 }}>
          <Typography component="p" variant="h4" color="primary">
            Histórico de Transações
          </Typography>
          {transactions && transactions.length > 0 ? (
            <TransactionTimeline
              onClick={handleTransactionDetails}
              transactions={transactions}
            />
          ) : isFetching ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <CircularProgress size={20} />
            </div>
          ) : (
            <Empty
              description={"Nenhuma Transação encontrada"}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )}
          {hasNextPage && (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Button
                disabled={isFetchingNextPage}
                onClick={handleFetchNextPage}
                sx={{ width: "150px" }}
              >
                {isFetchingNextPage ? (
                  <CircularProgress size={20} />
                ) : (
                  <> Carregar mais</>
                )}
              </Button>
            </div>
          )}
        </Paper>
      </Container>
      {showModalAddCredit && (
        <AddCreditModal
          open={showModalAddCredit}
          onClose={() => setShowModalAddCredit(false)}
        />
      )}

      {showTransactionDetails && currentTransaction && (
        <TransactionDetailsModal
          transaction={currentTransaction}
          open={showTransactionDetails}
          onClose={() => setShowTransactionDetails(false)}
        />
      )}
    </>
  );
};

export default Balance;
