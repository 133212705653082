/* eslint-disable no-param-reassign */
import axios from 'axios';

const api = axios.create({ baseURL: process.env.REACT_APP_API_BASE_URL });

api.interceptors.request.use(async (config) => {
  try {
    const token = JSON.parse((await localStorage.getItem('@legal_audit_token')) || '');

    if (token && config?.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  } catch {
    //...
  }
  return config;
});
export default api;
