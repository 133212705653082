import { Cancel } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Modal, Modals } from "components";
import { ModalProps } from "..";
import { ChangeEvent, useEffect, useState } from "react";
import { ICollaboratorUserViewModel } from "viewModels";
import { Controller, useForm } from "react-hook-form";
import { IUpdateCollaboratorUserInputModel } from "inputModels";
import { CollaboratorUserController } from "controllers/CollaboratorUserController";
import { useCollaboratorUser } from "contexts/collaboratorUser";
import { EmailController } from "controllers/EmailController";
import { usePersistedState } from "hooks";
import { ManagementStatus } from "types/enums";
import { decryptCode } from "utils/crypto";
import { useAlert } from "contexts";

interface IData {
  contactValue: string;
  contactLabel: string;
  codeExpected: string;
}

interface EditCollaboratorUserModalProps extends ModalProps {
  dataCode: IData;
  collaboratorUser: ICollaboratorUserViewModel;
  onCodeChange: (newCode: string) => void;
}

const EditCollaboratorUserModal: React.FC<EditCollaboratorUserModalProps> = ({
  onCodeChange,
  collaboratorUser,
  dataCode,
  ...props
}: EditCollaboratorUserModalProps) => {
  const TIMER = 60000;
  const alert = useAlert();
  const { setCollaboratorUsers } = useCollaboratorUser();
  const [expectedCode, setExpectedCode] = useState("");
  const [isOpenRecoverPasswordModal, setIsOpenRecoverPasswordModal] =
    useState(false);
  const [selected, setSelected] =
    useState<ICollaboratorUserViewModel>(collaboratorUser);
  const [requesting, setRequesting] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [disabledEmailField, setDisabledEmailField] = useState(false);
  const [emailValue, setEmailValue] = useState(collaboratorUser.email);
  const [editEmail, setEditEmail] = useState(false);
  const [editManagementStatus, setEditManagementStatus] = useState(
    collaboratorUser.managementStatus
  );
  const [dateToSend, setDateToSend] = usePersistedState<number | undefined>(
    "dateToSend",
    undefined
  );
  const [disableResend, setDisableResend] = useState(
    dateToSend === undefined ? false : dateToSend + TIMER > new Date().getTime()
  );
  const [secondsRemainingToResend, setSecondsRemainingToResend] = useState(
    TIMER / 1000
  );
  const [defaultValues] = useState({
    name: collaboratorUser.name,
    email: collaboratorUser.email,
    code: "",
  });
  const {
    control,
    setValue,
    watch,
    clearErrors,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    shouldFocusError: false,
    reValidateMode: "onChange",
    defaultValues: defaultValues,
  });

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (dateToSend !== undefined) {
      interval = setInterval(() => {
        const isDisabled = dateToSend + TIMER > new Date().getTime();
        setDisableResend(isDisabled);
        setSecondsRemainingToResend((prev) => prev - 1);
        if (!isDisabled) {
          setSecondsRemainingToResend(TIMER / 1000);
          setDateToSend(undefined);
          clearInterval(interval);
        }
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [dateToSend, setDateToSend]);

  useEffect(() => {
    setEditManagementStatus(collaboratorUser.managementStatus);
  }, [collaboratorUser.managementStatus]);

  const code = watch("code");
  const data = watch();

  const verifyEmail = async () => {
    let hasError = false;
    const alreadyEmail = await CollaboratorUserController.verifyAlreadyEmail(
      emailValue
    );
    if (alreadyEmail.data) {
      setError("email", { message: "Este email já existe" });
      hasError = true;
    }
    return hasError;
  };

  const handleUpdateCollaboratorUser = async () => {
    clearErrors();

    if (editEmail) {
      if (!emailValue) {
        setError("email", { message: "Informe o email" });
        return;
      }

      if (await verifyEmail()) return;
    } else if (!data.name) {
      setError("name", { message: "Informe o nome" });
      return;
    }

    if (!selected.email && !emailValue) {
      alert.show({
        type: "alert",
        title: `Erro`,
        timeout: 5000,
        description: "É necessario adicionar um Email",
      });
      return;
    }

    const update: IUpdateCollaboratorUserInputModel = {
      id: collaboratorUser.id,
      name: data.name,
      email: emailValue ?? selected.email,
      managementStatus: editManagementStatus,
    };
    setRequesting(true);
    CollaboratorUserController.updateUser(update)
      .then(() => {
        setCollaboratorUsers((prev) => {
          const index = prev.findIndex((m) => m.id === collaboratorUser.id);
          prev[index] = {
            ...prev[index],
            name: data.name,
            email: emailValue ?? selected.email,
            managementStatus: editManagementStatus,
          };
          return [...prev];
        });
        setDateToSend(undefined);
        setDisableResend(false);
        props?.onClose();
      })
      .finally(() => {
        setRequesting(false);
        clearErrors();
        setValue("code", "");
        setDisabledEmailField(false);
      });
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    const currentStatus = editManagementStatus as ManagementStatus[] || [];
  
    if (currentStatus.includes(value)) {
      setEditManagementStatus(currentStatus.filter(status => status !== value));
    } else {
      setEditManagementStatus([...currentStatus, value]);
    }
  };
  

  const handleOnCallback = () => {
    if (!editEmail) return handleUpdateCollaboratorUser();
    if (dataCode.codeExpected === code && code !== "") {
      handleUpdateCollaboratorUser();
    } else {
      setError("code", {
        message: "Código não corresponde",
      });
    }
    setDisabledEmailField(false);
  };

  const handleEditEmail = () => {
    setEditEmail(!editEmail);
  };

  const handleSendConfirmationEmail = async () => {
    if (!collaboratorUser?.id) return;
    if (!emailValue) {
      setError("email", {
        message: "Insira um email",
      });
      setDisabledEmailField(false);
      return;
    }
    setDisabledEmailField(true);
    setDateToSend(new Date().getTime());
    EmailController.sendCodeVerification({
      to: emailValue,
      subject: "Edição de e-mail",
      description: "Utilize o código abaixo para editar seu email no Legal Control Audit:",
    }).then((resCode) => {
      onCodeChange(decryptCode(resCode));
      setDisabled(false);
    });
  };

  useEffect(() => {
    setValue("name", collaboratorUser.name);
    setValue("email", collaboratorUser.email);
    setSelected(collaboratorUser);
  }, [collaboratorUser, setValue]);

  return (
    <Modal
      {...props}
      sx={{
        position: "relative",
        width: "26rem",
        height: "34rem",
        overflowX: "hidden",
        overflowY: "auto",
        p: 1,
      }}
    >
      <IconButton
        size="small"
        onClick={props?.onClose}
        color="error"
        sx={{
          position: "absolute",
          top: "5px",
          right: "5px",
        }}
      >
        <Cancel />
      </IconButton>

      <Container style={{ height: "95%" }}>
        <div
          style={{
            height: "100%",
            position: "relative",
          }}
        >
          <Typography
            variant="h5"
            color="primary"
            marginTop={1}
            marginBottom={2}
          >
            Editar Usuário
          </Typography>
          <Grid marginBottom={2}>
            <Controller
              name="name"
              control={control}
              render={({ field: { onChange, value, ...rest } }) => (
                <TextField
                  fullWidth
                  onChange={(e) => {
                    onChange(e.target.value);
                    clearErrors("name");
                  }}
                  value={value}
                  error={!!errors.name?.message}
                  helperText={errors.name?.message}
                  size="small"
                  label="Nome"
                  {...rest}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} marginBottom={2}>
            <Grid display="flex" justifyContent="flex-start" gap={1} marginLeft={-1}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={editManagementStatus?.includes(ManagementStatus.MODEL) ?? false}
                    onChange={handleChange}
                    value={ManagementStatus.MODEL}
                  />
                }
                label="Gerenciar Modelos"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={editManagementStatus?.includes(ManagementStatus.USER) ?? false}
                    onChange={handleChange}
                    value={ManagementStatus.USER}
                  />
                }
                label="Gerenciar Usuários"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={editManagementStatus?.includes(ManagementStatus.PODEN) ?? false}
                    onChange={handleChange}
                    value={ManagementStatus.PODEN}
                  />
                }
                label="Gerenciar Ponderadores"
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            display="flex"
            alignItems="center"
            justifyContent="center"
            marginBottom={2}
          >
            <Button
              style={{ width: "12rem" }}
              type="button"
              variant="contained"
              onClick={handleEditEmail}
              sx={{ cursor: requesting ? "wait" : "pointer" }}
            >
              <Typography variant="button">Editar Email</Typography>
            </Button>
          </Grid>
          {editEmail && (
            <>
              <Grid marginBottom={2}>
                <Controller
                  disabled={disabledEmailField}
                  name="email"
                  control={control}
                  render={({ field: { onChange, value, ...rest } }) => (
                    <TextField
                      fullWidth
                      onChange={(e) => {
                        onChange(e.target.value);
                        setEmailValue(e.target.value);
                        clearErrors("email");
                      }}
                      value={emailValue ?? ""}
                      error={!!errors.email?.message}
                      helperText={errors.email?.message}
                      size="small"
                      label="E-mail"
                      {...rest}
                    />
                  )}
                />
              </Grid>
              <Grid display="flex" justifyContent="center" marginBottom={2}>
                <Button
                  style={{ width: "12rem" }}
                  disabled={disableResend}
                  type="submit"
                  variant="contained"
                  sx={{ cursor: requesting ? "wait" : "pointer" }}
                  onClick={() => {
                    handleSendConfirmationEmail();
                  }}
                >
                  <Typography variant="button">
                    {disableResend
                      ? `Enviar código (${secondsRemainingToResend})`
                      : "Enviar código"}
                  </Typography>
                </Button>
              </Grid>
              <Grid marginBottom={2}>
                <Controller
                  name="code"
                  control={control}
                  render={({ field: { onChange, value, ...rest } }) => (
                    <TextField
                      fullWidth
                      disabled={disabled}
                      onChange={onChange}
                      value={value}
                      size="small"
                      label="Código de Verificação"
                      helperText={errors.code?.message}
                      error={!!errors.code}
                      placeholder="Insira o código de verificação"
                      {...rest}
                    />
                  )}
                />
              </Grid>
            </>
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: "0.3rem",
              position: "fixed",
              bottom: "0",
              left: "0",
              marginBottom: "10px",
              width: "100%",
            }}
          >
            <Button
              style={{ width: "10rem" }}
              type="button"
              variant="contained"
              onClick={() => {
                setIsOpenRecoverPasswordModal(true);
              }}
              sx={{ cursor: requesting ? "wait" : "pointer" }}
            >
              <Typography variant="button">Recuperar Senha</Typography>
            </Button>
            <Button
              style={{ width: "10rem" }}
              type="button"
              variant="contained"
              onClick={handleSubmit(handleOnCallback)}
              sx={{ cursor: requesting ? "wait" : "pointer" }}
            >
              <Typography variant="button">Salvar</Typography>
              {requesting && (
                <CircularProgress size={15} sx={{ ml: 1, color: "white" }} />
              )}
            </Button>
          </div>
        </div>
      </Container>
      <Modals.RecoverPasswordModal
        open={isOpenRecoverPasswordModal}
        typeModal="changePassword"
        email={collaboratorUser.email}
        onCodeChange={(codeChange) => setExpectedCode(codeChange)}
        collaboratorUserId={collaboratorUser.id}
        data={{
          codeExpected: expectedCode,
          contactLabel: "E-mail",
          contactValue: data.email,
        }}
        onClose={() => setIsOpenRecoverPasswordModal(false)}
      />
    </Modal>
  );
};

export default EditCollaboratorUserModal;
