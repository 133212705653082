import {
  IModelDossierInputModel,
  IOrderedWithPaginationInputModel,
  IUpdateModelDossierInputModel,
} from "inputModels";
import api from "services/api";
import { IModelDossierViewModel, IResponse, IVoidResponse } from "viewModels";

const updateDisableModel = (id: string, status: boolean) =>
  new Promise<IVoidResponse>((resolve, reject) => {
    api
      .put(`modeldossier/${id}`, status, {
        headers: { "Content-Type": "application/json" },
      })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const getByModelId = <T>(input?: IOrderedWithPaginationInputModel<T>) =>
  new Promise<IResponse<IModelDossierViewModel[]>>((resolve, reject) => {
    api
      .get(
        `modeldossier/get-by-model-id?page=${input?.page}&take=${input?.take}${
          input?.term ? `&term=${input?.term}` : ""
        }${input?.filter ? `&filter=${input?.filter}` : ""}&${input?.orderBy
          ?.map(
            (o, i) =>
              `orderBy[${i}].key=${o.key}&orderBy[${i}].direction=${o.direction}`
          )
          .join("&")}`
      )
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const insert = (input: IModelDossierInputModel) =>
  new Promise<IResponse<string>>((resolve, reject) => {
    api
      .post(`modeldossier/`, input)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const getAll = () =>
  new Promise<IResponse<IModelDossierViewModel[]>>((resolve, reject) => {
    api
      .get(`modeldossier/`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const updateModel = (update: IUpdateModelDossierInputModel) =>
  new Promise<IVoidResponse>((resolve, reject) => {
    api
      .put(`modeldossier/`, update)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
export const ModelDossierController = {
  updateDisableModel,
  insert,
  getAll,
  getByModelId,
  updateModel,
};
