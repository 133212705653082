import {
  Cancel,
  ClearAll,
  DoneAll,
  KeyboardDoubleArrowDown,
  Notifications,
} from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useNotification } from "contexts";
import React, { useState } from "react";
import { BoxNotification } from "./styles";

const Notification: React.FC = () => {
  const theme = useTheme();

  const {
    hasNotifications,
    notifications,
    read,
    readAll,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    remove,
    removeAll,
    isLoading,
  } = useNotification();

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenNotifications = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNotifications = () => {
    setAnchorElUser(null);
  };

  return (
    <Box>
      <Tooltip title="Notificações">
        <IconButton onClick={handleOpenNotifications}>
          <Badge variant="dot" color="warning" invisible={!hasNotifications}>
            <Notifications color="primary" />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="notifications"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseNotifications}
      >
        <BoxNotification data-testid='boxNotification'>
          <Box sx={{ width: 350 }}>
            <Grid
              container
              sx={{
                pl: 2,
                pr: 1,
                pb: 1,
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #ddd",
              }}
            >
              <Typography
                component="strong"
                color="primary"
                sx={{
                  fontWeight: "bold",
                }}
              >
                Notificações
              </Typography>
              <Box>
                <Tooltip title="Ler todas">
                  <span>
                    <IconButton
                      data-testid='buttonReadAll'
                      disabled={
                        isLoading ||
                        isFetchingNextPage ||
                        notifications.length === 0
                      }
                      color="primary"
                      onClick={() => readAll()}
                    >
                      <DoneAll />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title="Remover todas">
                  <span>
                    <IconButton 
                      data-testid='buttonRemoveAll'
                      disabled={
                        isLoading ||
                        isFetchingNextPage ||
                        notifications.length === 0
                      }
                      color="primary"
                      onClick={() => removeAll()}
                    >
                      <ClearAll />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            </Grid>
            <List
              dense
              sx={{
                maxHeight: "40vh",
                overflowY: "auto",
                "& li:not(:last-child)": { borderBottom: "1px solid #ddd" },
              }}
            >
              {isLoading ? (
                <Grid
                  item
                  alignItems="center"
                  sx={{ minHeight: "65px !important" }}
                >
                  <CircularProgress size={15} />
                  <Typography
                    paddingLeft={1}
                    variant="caption"
                    color="text"
                    sx={{ fontStyle: "italic" }}
                  >
                    Carregando notificações...
                  </Typography>
                </Grid>
              ) : notifications.length === 0 ? (
                <Typography
                  paddingLeft={2}
                  variant="caption"
                  color="text"
                  sx={{ fontStyle: "italic" }}
                >
                  Nenhuma notificação para exibir...
                </Typography>
              ) : (
                notifications.map(({ id, ...n }) => (
                  <ListItem
                    data-testid='listNotification'
                    key={id}
                    alignItems="flex-start"
                    onClick={() => read(id)}
                    sx={{
                      transition: "all 0.5s ease-in-out",
                      cursor: "pointer",
                      borderRadius: 1,
                      backgroundColor: n.readed
                        ? "unset"
                        : `${theme.palette.primary.main}11`,
                      userSelect: "none",
                      ":hover": {
                        backgroundColor: `${theme.palette.primary.main}22`,
                      },
                    }}
                    secondaryAction={
                      <IconButton
                        data-testid='buttonCancel'
                        color="error"
                        size="small"
                        onClick={() => remove(id)}
                      >
                        <Cancel />
                      </IconButton>
                    }
                  >
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: `${theme.palette.primary.main}22` }}>
                        <Notifications color="primary" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primaryTypographyProps={{
                        color: theme.palette.primary.main,
                        fontWeight: 600,
                      }}
                      primary={n.title}
                      secondary={n.body}
                    />
                  </ListItem>
                ))
              )}
              {hasNextPage && (
                <ListItem sx={{ justifyContent: "center" }}>
                  {isFetchingNextPage ? (
                    <Grid
                      item
                      alignItems="center"
                      sx={{ minHeight: "65px !important" }}
                    >
                      <CircularProgress size={15} />
                      <Typography
                        paddingLeft={1}
                        variant="caption"
                        color="text"
                        sx={{ fontStyle: "italic" }}
                      >
                        Carregando notificações...
                      </Typography>
                    </Grid>
                  ) : (
                    <Button onClick={() => fetchNextPage()} sx={{ px: 2 }}>
                      <KeyboardDoubleArrowDown />
                      <Typography paddingLeft={2} variant="button">
                        Carregar mais...
                      </Typography>
                    </Button>
                  )}
                </ListItem>
              )}
            </List>
          </Box>
        </BoxNotification>
      </Menu>
    </Box>
  );
};

export default Notification;
