import styled, { css } from "styled-components";

export const TimeLineContent = styled.div`
  margin-top: 10px;
  .icon {
    margin: 2px 0;
  }
  .date {
    font-size: smaller;
    color: ${({ theme }) => theme.colors.text_grey};
  }

  .hour {
    font-size: smaller;
    color: ${({ theme }) => theme.colors.text_grey};
    text-align: center;
  }

  .id {
    font-size: smaller;
    color: ${({ theme }) => theme.colors.text_grey};
    margin-left: 10px;
  }
  .amount {
    font-size: small;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.text_grey};
    margin-left: 10px;
  }

  .timeline-content {
    padding: 0 5px;
    margin-bottom: 20px;
  }
`;

export const TimeLineRow = styled.div`
  border-radius: 8px;
  padding: 1px 3%;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

export const Title = styled.div`
  display: flex;

  .icon-title {
    margin: 0 5px;
    padding: 3px;
  }

  p {
    font-size: large;
    color: ${({ theme }) => theme.colors.text_grey};
    text-align: center;
  }
`;
