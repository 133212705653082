import {
  IOrderedWithPaginationInputModel,
  IUpdateCompanyUserInputModel,
} from "inputModels";
import { api } from "services";
import { IResponse, IUserViewModel, IVoidResponse } from "viewModels";

const getByCompanyId = <T>(input?: IOrderedWithPaginationInputModel<T>) =>
  new Promise<IResponse<IUserViewModel[]>>((resolve, reject) => {
    api
      .get(
        `companyuser/get-by-company-id?page=${input?.page}&take=${input?.take}${
          input?.term ? `&term=${input?.term}` : ""
        }${input?.filter ? `&filter=${input?.filter}` : ""}&${input?.orderBy
          ?.map(
            (o, i) =>
              `orderBy[${i}].key=${o.key}&orderBy[${i}].direction=${o.direction}`
          )
          .join("&")}`
      )
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const updateDisableStatusCompany = (id: string, status: boolean) =>
  new Promise<IVoidResponse>((resolve, reject) => {
    api
      .put(`companyuser/${id}`, status, {
        headers: { "Content-Type": "application/json" },
      })
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const updateUser = (update: IUpdateCompanyUserInputModel) =>
  new Promise<IVoidResponse>((resolve, reject) => {
    api
      .put(`companyuser`, update)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const verifyAlreadyEmail = (email: string) =>
  new Promise<IResponse<boolean>>((resolve, reject) => {
    api
      .get(`companyuser/${email}`)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const CompanyUserController = {
  getByCompanyId,
  updateDisableStatusCompany,
  updateUser,
  verifyAlreadyEmail,
};
