import { Loading } from "components";
import { AppContextProps } from "contexts-types";
import { useOnlineStatus } from "hooks";
import { Props } from "props";
import React, { createContext, useContext, useEffect, useState } from "react";

const AppContext = createContext<AppContextProps>({} as AppContextProps);

const App: React.FC<Props> = ({ children }) => {
  const online = useOnlineStatus();
  const [loading, setLoading] = useState(false);
  const [isExitConfirm, setIsExitConfirm] = useState(false);

  useEffect(() => {
    if (!isExitConfirm) return;

    const handleOnBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      // eslint-disable-next-line no-return-assign
    };
    window.addEventListener("beforeunload", handleOnBeforeUnload, {
      capture: true,
    });

    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener("beforeunload", handleOnBeforeUnload, {
        capture: true,
      });
    };
  }, [isExitConfirm]);

  return (
    <AppContext.Provider
      value={{
        online,
        loading,
        setLoading,
        isExitConfirm,
        setIsExitConfirm,
      }}
    >
      {children}
      <Loading shown={loading} />
      {!online && <p>Você está trabalhando offline...</p>}
    </AppContext.Provider>
  );
};

export const useApp: () => AppContextProps = () => useContext(AppContext);

export default App;
