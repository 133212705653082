import React, { useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {
  PaymentIntentResult,
  StripePaymentElementOptions,
} from "@stripe/stripe-js";
import { Button, CircularProgress } from "@mui/material";

// Definindo as props para o componente
interface CheckoutFormProps {
  dpmCheckerLink: string;
  onResponse: (response: PaymentIntentResult) => void;
}

const CheckoutForm: React.FC<CheckoutFormProps> = ({
  dpmCheckerLink,
  onResponse,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    stripe
      .confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.href,
        },
        redirect: "if_required",
      })
      .then((response) => {
        if (response.error) {
          return;
        }
        onResponse(response);
      })
      .finally(() => setIsLoading(false));
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: "tabs",
  };

  return (
    <div
      style={{
        width: "80%",
        maxWidth: "500px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <Button
        disabled={isLoading || !stripe || !elements}
        id="submit"
        fullWidth
        variant="contained"
        onClick={handleSubmit}
        sx={{
          height: "40px",
          marginTop: "15px",
          borderRadius: "10px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <span id="button-text">
          {isLoading ? (
            <div
              style={{
                height: "40px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <CircularProgress size="25px" color="primary" />
            </div>
          ) : (
            "Pagar agora"
          )}
        </span>
      </Button>
    </div>
  );
};

export default CheckoutForm;
