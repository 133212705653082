import { Search } from "@mui/icons-material";
import {
  Grid,
  SxProps,
  TextField,
  Theme,
} from "@mui/material";
import { MASK_CNPJ, MASK_CPF } from "../../constants";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toPattern } from "vanilla-masker";

type FilterKey = "document" | "description" | "date" | "status";

interface FilterInputProps {
  sx?: SxProps<Theme>;
  onInputChange?: (change: { term?: string; filter?: string; }) => void;
}

const FilterInput: React.FC<FilterInputProps> = ({ sx, onInputChange }) => {
  const [term, setTerm] = useState<string>();
  const inputRef = useRef<HTMLDivElement>(null);
  const [selectedFilter] = useState<FilterKey>();
  const [mask, setMask] = useState('');
  const [defaultValues] = useState({
    filter: "",
  });

  const handleSearch = useCallback(
    () => onInputChange?.({ term, filter: selectedFilter }),
    [onInputChange, selectedFilter, term]
  );

  useEffect(() => {
    setTerm(undefined);
  }, [selectedFilter]);

  useEffect(() => {
    const timer = setTimeout(handleSearch, 1000);
    return () => clearTimeout(timer);
  }, [handleSearch]);

  const handleTermChange = (newTerm: string) => {
    const currentTerm = newTerm.trim();
    setTerm(currentTerm === "" ? undefined : currentTerm);
  };

  const {
    control,
    watch,
  } = useForm({
    shouldFocusError: false,
    reValidateMode: "onChange",
    defaultValues: useMemo(() => defaultValues, [defaultValues]),
  });
  const data = watch();

  useEffect(() => {
    if (selectedFilter === "document") {
      data.filter.length > 14 ?
        setMask(MASK_CNPJ)
        :
        setMask(MASK_CPF);
    }
  }, [data.filter.length, selectedFilter]);

  return (
    <>
      <Grid container justifyContent={"flex-end"} sx={sx}>
        <Grid item ref={inputRef}>
          <Controller
            control={control}
            name="filter"
            render={({ field: { onChange, value } }) => (
              <TextField
                size="small"
                label="Pesquisar"
                variant="standard"
                type={selectedFilter === "date" ? "date" : "text"}
                placeholder="O que deseja buscar?"
                sx={{ width: 200 }}
                onChange={(e) => {
                  handleTermChange(e.target.value);
                  onChange(e.target.value);
                }}
                value={mask ? toPattern(value, mask) : value}
                InputProps={{
                  sx: {
                    pl: 1,
                  },
                  endAdornment: <Search />,
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FilterInput;
