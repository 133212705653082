import { IConsultCnpj, IConsultCnpjResponse } from "types/entities";
import { formatDate } from "./formateDate";

export const convertConsultCnpjResponseToConsultCnpj = (
  consultCnpjResponse: IConsultCnpjResponse,
): IConsultCnpj => {
  const newResponse: IConsultCnpj = {
    document: consultCnpjResponse.cnpj,
    companyName: consultCnpjResponse.razao_social,
    matrix: consultCnpjResponse.identificador_matriz_filial,
    fantasyName: consultCnpjResponse.nome_fantasia,
    openDate: formatDate(consultCnpjResponse.data_inicio_atividade),
    cadastralSituationDescription: consultCnpjResponse.descricao_situacao_cadastral,
    cadastralSituationDate: formatDate(consultCnpjResponse.data_situacao_cadastral),
    cadastralSituationMotive: consultCnpjResponse.motivo_situacao_cadastral,
    specialSituation: consultCnpjResponse.situacao_especial,
    specialSituationDate: formatDate(consultCnpjResponse.data_situacao_especial),
    address: {
      neighborhood: consultCnpjResponse.bairro,
      number: consultCnpjResponse.numero,
      city: consultCnpjResponse.municipio,
      street: consultCnpjResponse.logradouro,
      complement: consultCnpjResponse.complemento,
      state: consultCnpjResponse.uf,
      zipCode: consultCnpjResponse.cep,
      country: consultCnpjResponse.pais,
    },
    qsa: consultCnpjResponse?.qsa?.map((item) => ({
      country: item.pais,
      partnerName: item.nome_socio,
      countryCode: item.codigo_pais,
      ageRange: item.faixa_etaria,
      documentPartner: item.cnpj_cpf_do_socio,
      qualificationPartner: item.qualificacao_socio,
      ageRangeCode: item.codigo_faixa_etaria,
      entryDate: formatDate(item.data_entrada_sociedade),
      partnerIdentifier: item.identificador_de_socio,
      legalRepCpf: item.cpf_representante_legal,
      legalRepName: item.nome_representante_legal,
      qualificationPartnerCode: item.codigo_qualificacao_socio,
      legalRepQualification: item.qualificacao_representante_legal,
      legalRepQualificationCode: item.codigo_qualificacao_representante_legal,
    })),
  };
  return newResponse;
};

