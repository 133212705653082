import { IOrderedWithPaginationInputModel } from "inputModels";
import api from "services/api";
import { ILogViewModel, IResponse } from "viewModels";

const listAll = <T>(input?: IOrderedWithPaginationInputModel<T>) =>
  new Promise<IResponse<ILogViewModel[]>>((resolve, reject) => {
    api
      .get(
        `log/get-all?page=${input?.page}&take=${input?.take}${
          input?.term ? `&term=${input?.term}` : ""
        }${input?.filter ? `&filter=${input?.filter}` : ""}&${input?.orderBy
          ?.map(
            (o, i) =>
              `orderBy[${i}].key=${o.key}&orderBy[${i}].direction=${o.direction}`
          )
          .join("&")}`
      )
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const LogController = {
  listAll,
};
