import { RecklessTraderContextProps } from "contexts-types";
import { RecklessTraderController } from "controllers";
import { Props } from "props";
import React, { createContext, useContext, useEffect, useState } from "react";
import {
  IRecklessTraderReportViewModel,
  IRecklessTraderViewModel,
} from "viewModels";
import { useAuth } from "./auth";
const RecklessTraderContext = createContext<RecklessTraderContextProps>(
  {} as RecklessTraderContextProps
);

const RecklessTraderProvider: React.FC<Props> = ({ children }) => {
  const { logged } = useAuth();
  const [recklessTraders, setRecklessTraders] = useState<
    IRecklessTraderViewModel[]
  >([]);
  const [recklessTradersReport, setRecklessTradersReport] = useState<
    IRecklessTraderReportViewModel[]
  >([]);

  useEffect(() => {
    if (!logged) {
      return;
    }
    RecklessTraderController.GetAll().then((recklessTrader) => {
      setRecklessTraders(recklessTrader);
    });
    RecklessTraderController.GetAllReports().then((recklessTraderReport) => {
      setRecklessTradersReport(recklessTraderReport);
    });
  }, [logged]);

  return (
    <RecklessTraderContext.Provider
      value={{
        recklessTraders,
        setRecklessTraders,
        recklessTradersReport,
        setRecklessTradersReport,
      }}
    >
      {children}
    </RecklessTraderContext.Provider>
  );
};

export const useRecklessTrader: () => RecklessTraderContextProps = () =>
  useContext(RecklessTraderContext);

export default RecklessTraderProvider;
