import {
  IOrderedWithPaginationInputModel,
  IPaymentIntentInputModel,
} from "inputModels";
import {
  FinancialHistoryDataViewModel,
  IFinancialCardsDataViewModel,
  IPaymentIntentViewModel,
  IResponse,
  ITransactionViewModel,
} from "viewModels";
import api from "services/api";

const createPaymentIntent = (input: IPaymentIntentInputModel) =>
  new Promise<IResponse<IPaymentIntentViewModel>>((resolve, reject) => {
    api
      .post("payments/create-payment-intent", input)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const getUserBalance = () =>
  new Promise<IResponse<number>>((resolve, reject) => {
    api
      .get("payments/get-user-balance")
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const getAllTransactions = <T>(input?: IOrderedWithPaginationInputModel<T>) =>
  new Promise<IResponse<ITransactionViewModel[]>>((resolve, reject) => {
    api
      .get(
        `payments/get-user-transactions?page=${input?.page}&take=${
          input?.take
        }${input?.term ? `&term=${input?.term}` : ""}${
          input?.filter ? `&filter=${input?.filter}` : ""
        }&${input?.orderBy
          ?.map(
            (o, i) =>
              `orderBy[${i}].key=${o.key}&orderBy[${i}].direction=${o.direction}`
          )
          .join("&")}`
      )
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const getDashboardCardsData = () =>
  new Promise<IResponse<IFinancialCardsDataViewModel>>((resolve, reject) => {
    api
      .get("payments/get-financial-cards-data")
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

const getFinancialHistoryData = () =>
  new Promise<IResponse<FinancialHistoryDataViewModel[]>>((resolve, reject) => {
    api
      .get("payments/get-financial-history-data")
      .then(({ data }) => resolve(data))
      .catch(reject);
  });

export const PaymentsController = {
  createPaymentIntent,
  getUserBalance,
  getAllTransactions,
  getDashboardCardsData,
  getFinancialHistoryData,
};
