import { Cancel } from "@mui/icons-material";
import {
  Button,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { Modal } from "components";
import { useState } from "react";
import {
  ModalContent,
  ModalHeader,
  PaymentId,
  PaymentMessage,
  PaymentResultContainer,
  StatusMessage,
  Steps,
  Title,
} from "./styles";
import { PaymentsController } from "controllers/PaymentsController";
import { IPaymentIntentInputModel } from "inputModels";
import { NumericFormat } from "react-number-format";
import { Elements } from "@stripe/react-stripe-js";
import {
  Appearance,
  loadStripe,
  PaymentIntent,
  PaymentIntentResult,
} from "@stripe/stripe-js";
import CheckoutForm from "components/CheckoutForm";

import { BadgeAlert, BadgeCheck } from "lucide-react";
interface AddCreditModalProps {
  onClose: () => void;
  open: boolean;
}

interface AddCreditModalProps {
  onClose: () => void;
  open: boolean;
}

const steps = ["Valor", "Dados de Pagamento", "Confirmação"];
const stripePromise = loadStripe(
  "pk_test_51Q8lcBJ3wq7Ckzyerfg9mUek45YoVA3CTNwBpE552AUFD3kayRCH8erQsDny2D1Xj8jP7dm66DTMDFgZ45bCvWCn00l9e4BfuO"
);

function AddCreditModal({ onClose, open }: AddCreditModalProps) {
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [creditAmount, setCreditAmount] = useState<number>(0.0);
  const [amountError, setAmountError] = useState<boolean>(false);
  const [clientSecret, setClientSecret] = useState<string>("");
  const [dpmCheckerLink, setDpmCheckerLink] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paymentIntentResponse, setPaymentIntentResponse] =
    useState<PaymentIntent>();

  const getClientSecret = async () => {
    if (creditAmount <= 0 || creditAmount > 20) {
      setAmountError(true);
      return false;
    }
    setAmountError(false);

    const data: IPaymentIntentInputModel = {
      amount: Math.floor(creditAmount * 100),
      currency: "brl",
    };

    setIsLoading(true);
    PaymentsController.createPaymentIntent(data)
      .then((response) => {
        setClientSecret(response.data.clientSecret);
        setDpmCheckerLink(response.data.dpmCheckerLink ?? "");
        setStepIndex(1);
      })
      .catch()
      .finally(() => setIsLoading(false));
  };

  const handlePaymentResponse = (response: PaymentIntentResult) => {
    const { error, paymentIntent } = response;

    if (error) {
      console.log("Falha ao realizar o Pagamento", response);
    }
    if (paymentIntent) {
      setPaymentIntentResponse(paymentIntent);
      setStepIndex(2);

      console.log("Pagamento realizado", response);
    }
  };

  const appearance: Appearance = {
    theme: "flat",
    variables: {
      fontFamily: ' "Gill Sans", sans-serif',
      fontLineHeight: "1.5",
      borderRadius: "10px",
      colorBackground: "#F6F8FA",
      accessibleColorOnColorPrimary: "#262626",
    },
    rules: {
      ".Block": {
        backgroundColor: "var(--colorBackground)",
        boxShadow: "none",
        padding: "12px",
      },
      ".Input": {
        padding: "12px",
      },
      ".Input:disabled, .Input--invalid:disabled": {
        color: "lightgray",
      },
      ".Tab": {
        padding: "10px 12px 8px 12px",
        border: "none",
      },
      ".Tab:hover": {
        border: "none",
        boxShadow:
          "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)",
      },
      ".Tab--selected, .Tab--selected:focus, .Tab--selected:hover": {
        border: "none",
        backgroundColor: "#fff",
        boxShadow:
          "0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)",
      },
      ".Label": {
        fontWeight: "500",
      },
    },
  };

  const loader = "auto";

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        minWidth: "90vw",
        minHeight: "90vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ModalHeader>
        <Typography variant="h6" component="h2" color={"primary"}>
          Adicionar Créditos
        </Typography>
        <IconButton size="small" onClick={onClose} color="error">
          <Cancel />
        </IconButton>
      </ModalHeader>
      <Steps>
        <Stepper alternativeLabel>
          {steps.map((name, index) => {
            if (index === stepIndex) {
              return (
                <Step key={name} active>
                  <StepLabel>{name}</StepLabel>
                </Step>
              );
            } else if (index <= stepIndex) {
              return (
                <Step key={name} completed>
                  <StepLabel
                    StepIconProps={{
                      sx: {
                        "&.Mui-completed": {
                          color: "green",
                        },
                        "&.Mui-active": {
                          color: "primary",
                        },
                      },
                    }}
                  >
                    {name}
                  </StepLabel>
                </Step>
              );
            } else {
              return (
                <Step key={name}>
                  <StepLabel>{name}</StepLabel>
                </Step>
              );
            }
          })}
        </Stepper>
      </Steps>
      {stepIndex === 0 && (
        <ModalContent>
          <div style={{ width: "300px" }}>
            <Title>Quanto deseja adicionar? </Title>
            <NumericFormat
              value={creditAmount}
              onValueChange={(values, _) => {
                const value = values.floatValue;

                if (value !== undefined && value >= 1) {
                  setAmountError(false);
                  setCreditAmount(value);
                } else {
                  setAmountError(true);
                }
              }}
              prefix="R$  "
              decimalSeparator=","
              decimalScale={2}
              thousandSeparator="."
              customInput={TextField}
              variant="outlined"
              size="small"
              label="Valor"
              fullWidth
            />

            {amountError && (
              <Typography color="error" variant="caption">
                Insira um valor válido entre R$ 1,00 e R$ 100,00.
              </Typography>
            )}
            <Button
              disabled={creditAmount < 1 || creditAmount > 100}
              sx={{
                height: "40px",
                marginTop: "15px",
                display: "flex",
                alignItems: "center",
              }}
              variant="contained"
              onClick={getClientSecret}
              fullWidth
            >
              Continuar
            </Button>
          </div>
        </ModalContent>
      )}

      {stepIndex === 1 &&
        (isLoading ? (
          <div style={{ display: "flex", flexGrow: 1 }}>
            Carregando, aguarde...
          </div>
        ) : (
          <ModalContent>
            {clientSecret && (
              <Elements
                options={{ clientSecret, appearance, loader }}
                stripe={stripePromise}
              >
                <CheckoutForm
                  dpmCheckerLink={dpmCheckerLink}
                  onResponse={handlePaymentResponse}
                />
              </Elements>
            )}
          </ModalContent>
        ))}

      {stepIndex === 2 && (
        <ModalContent>
          <div
            style={{
              width: "400px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {paymentIntentResponse?.status === "succeeded" && (
              <PaymentResultContainer>
                <StatusMessage>
                  Seu pagamento foi concluído com sucesso
                </StatusMessage>
                <BadgeCheck
                  color="#3dd129"
                  size={100}
                  style={{ margin: "40px" }}
                />
                <PaymentMessage>
                  Seu saldo será atualizado em breve
                </PaymentMessage>
                <PaymentId>{paymentIntentResponse.id}</PaymentId>
              </PaymentResultContainer>
            )}

            {paymentIntentResponse?.status === "requires_action" && (
              <PaymentResultContainer>
                <StatusMessage>
                  Seu pagamento está sendo processado
                </StatusMessage>
                <BadgeAlert
                  color="#d19329"
                  size={100}
                  style={{ margin: "40px" }}
                />
                <PaymentMessage>
                  Seu saldo será adicionado automáticamente assim que o
                  pagamento for concluído
                </PaymentMessage>
                <PaymentId>{paymentIntentResponse.id}</PaymentId>
              </PaymentResultContainer>
            )}

            <Button
              sx={{
                height: "40px",
                width: "250px",
                marginTop: "15px",
                display: "flex",
                alignItems: "center",
              }}
              variant="contained"
              onClick={onClose}
            >
              Confirmar
            </Button>
          </div>
        </ModalContent>
      )}
    </Modal>
  );
}

export default AddCreditModal;
